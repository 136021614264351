import { UseCase } from '../../base/use-case';
import { PicklistItemModel } from '../../domain/picklist/picklist.model';
import PicklistRepository from '../../repositories/picklist.repository';

export default class ConvertPicklistUseCase implements UseCase<File, PicklistItemModel[]> {
  private picklistRepository : PicklistRepository;

  constructor(picklistRepository : PicklistRepository) {
    this.picklistRepository = picklistRepository;
  }

  execute(params: File): Promise<PicklistItemModel[]> {
    return this.picklistRepository.convertPicklist(params);
  }
}
