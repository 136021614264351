import { FromMapper } from '../../../../core/base/mapper';
import { UserEntity } from '../entities/user.entity';
import { UserModel } from '../../../../core/domain/user/user.model';

export default class UserMapper implements
    FromMapper<UserEntity, UserModel> {
  // eslint-disable-next-line class-methods-use-this
  mapFrom(param: UserEntity): UserModel {
    return { accessToken: param.accessToken };
  }
}
