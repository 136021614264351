import React, { FC } from 'react';
import QRCode from 'react-qr-code';
import { useNavigate, useParams } from 'react-router-dom';

interface AssignPicklistProps {}

const AssignPicklist: FC<AssignPicklistProps> = function AssignPicklist() : JSX.Element {
  const { picklistId } = useParams();
  const navigate = useNavigate();

  function qrCodeComponent(id : string) : JSX.Element {
    return (
      <div>
        <div className="mb-3">
          <div style={{ background: 'white', padding: '16px' }}><QRCode value={id} title="Picklist QR" /></div>
        </div>
        <h1 className="mb-3">Scan with pda to assign</h1>
        <button type="submit" className="btn btn-primary" onClick={() => navigate('/picklists/import', { replace: true })}>Go back</button>
      </div>
    );
  }

  function errorComponent() : JSX.Element {
    return <h1 className="mb-3">Cannot generate qr code</h1>;
  }

  return (
    <div className="d-flex justify-content-center container flex-column">
      {picklistId ? qrCodeComponent(picklistId) : errorComponent()}
    </div>
  );
};

export default AssignPicklist;
