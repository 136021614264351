import { UseCase } from '../../base/use-case';
import { PicklistModel } from '../../domain/picklist/picklist.model';
import PicklistRepository from '../../repositories/picklist.repository';

export default class ImportPicklistUseCase implements UseCase<PicklistModel, string> {
  private picklistRepository : PicklistRepository;

  constructor(picklistRepository : PicklistRepository) {
    this.picklistRepository = picklistRepository;
  }

  execute(params: PicklistModel): Promise<string> {
    return this.picklistRepository.savePicklist(params);
  }
}
