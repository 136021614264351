import React, {
  FC, FormEvent, useEffect, useState,
} from 'react';
import _ from 'lodash';
import {
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { UseCase } from '../../../core/base/use-case';
import { WarehouseModel } from '../../../core/domain/warehouse/warehouse.model';
import { AuthContextType, useAuth } from '../../shared/auth/Auth';
import Spinner from '../../shared/Spinner';

interface LoginProps {
  getWarehousesUseCase: UseCase<void, WarehouseModel[]>
}

const Login: FC<LoginProps> = function Login({ getWarehousesUseCase }) : JSX.Element {
  const [username, setUserName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [warehouseCode, setWarehouseCode] = useState<string>('');
  const [warehousesLoading, setWarehousesLoading] = useState<boolean>(true);
  const [warehousesLoadFailed, setWarehousesLoadFailed] = useState<boolean>(false);
  const [warehouses, setWarehouses] = useState<WarehouseModel[]>();
  const [loginInProgress, setLoginInProgress] = useState<boolean>();
  const [loginFailed, setLoginFailed] = useState<boolean>();

  const auth: AuthContextType = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    getWarehousesUseCase.execute()
      .then((fetchedWarehouses) => {
        setWarehouses(fetchedWarehouses);
        setWarehouseCode(fetchedWarehouses[0].warehouseCode);
        setWarehousesLoading(false);
      })
      .catch(() => {
        setWarehousesLoadFailed(true);
        setWarehousesLoading(false);
      });
  }, [getWarehousesUseCase]);

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();
    if (username && password) {
      setLoginInProgress(true);
      auth.login({ username, password, warehouseCode }).then(() => {
        setLoginFailed(false);
        setLoginInProgress(false);
        const state = location.state as { from: Location };
        const from = state ? state.from.pathname : '/';
        navigate(from, { replace: true });
      }).catch(() => {
        setLoginFailed(true);
        setLoginInProgress(false);
      });
    }
  }

  function areInputsValid(): boolean {
    return !_.isEmpty(username) && !_.isEmpty(password) && !_.isEmpty(warehouseCode);
  }

  function loginFailedComponent(): JSX.Element {
    return (
      <div className="mb-3">
        <div className="alert alert-danger" role="alert">
          Login failed
        </div>
      </div>
    );
  }

  function warehouseSelectComponent(): JSX.Element {
    return (
      <div className="mb-3">
        <label className="form-label" htmlFor="warehouseSelect">Warehouse</label>
        <select
          id="warehouseSelect"
          className="form-select form-select-lg mb-3"
          value={warehouseCode}
          onChange={(e) => setWarehouseCode(e.target.value)}
          disabled={warehousesLoadFailed}
        >
          {warehouses ? warehouses
            .map((warehouse) => {
              const { warehouseCode: code, name } = warehouse;
              return <option key={code} value={code}>{name}</option>;
            })
            : null}
        </select>
      </div>
    );
  }

  function loginComponent() : JSX.Element {
    return (
      <form onSubmit={handleSubmit}>
        {loginFailed ? loginFailedComponent() : null}
        <div className="mb-3">
          <label className="form-label" htmlFor="usernameInput">Username</label>
          <input
            value={username}
            type="text"
            className="form-control"
            id="usernameInput"
            onChange={(e) => setUserName(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="passwordInput">Password</label>
          <input
            value={password}
            type="password"
            className="form-control"
            id="passwordInput"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="mb-3">
          {warehousesLoading ? <Spinner /> : warehouseSelectComponent()}
        </div>
        <div>
          <button type="submit" className="btn btn-primary" disabled={!areInputsValid()}>Submit</button>
        </div>
      </form>
    );
  }

  return (
    <div className="d-flex justify-content-center container">
      <div>
        <h1>Please Log In</h1>
        {loginInProgress ? <Spinner /> : loginComponent()}
      </div>
    </div>
  );
};

export default Login;
