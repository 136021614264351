import PicklistRepository from '../../../core/repositories/picklist.repository';
import PicklistApiService from './picklist-api.service';
import { PicklistItemModel, PicklistModel } from '../../../core/domain/picklist/picklist.model';
import { ToMapper } from '../../../core/base/mapper';
import { PicklistEntity } from './entities/picklist.entity';
import PicklistExcelService from './picklist-excel.service';

export default class PicklistRepositoryImpl implements PicklistRepository {
  private picklistApiService : PicklistApiService;

  private picklistExcelService : PicklistExcelService;

  private picklistMapper : ToMapper<PicklistModel, PicklistEntity>;

  constructor(
    picklistApiService : PicklistApiService,
    picklistExcelService : PicklistExcelService,
    picklistMapper : ToMapper<PicklistModel, PicklistEntity>,
  ) {
    this.picklistApiService = picklistApiService;
    this.picklistExcelService = picklistExcelService;
    this.picklistMapper = picklistMapper;
  }

  savePicklist(picklist : PicklistModel): Promise<string> {
    return this.picklistApiService.importPicklist(this.picklistMapper.mapTo(picklist));
  }

  convertPicklist(picklistFile: File): Promise<PicklistItemModel[]> {
    return this.picklistExcelService.convert(picklistFile);
  }
}
