import axios from 'axios';
import { UserEntity } from './entities/user.entity';
import { UserCredentialsEntity } from './entities/user-credentials.entity';

export default class UserApiService {
  // eslint-disable-next-line class-methods-use-this
  public login(userCredentials: UserCredentialsEntity): Promise<UserEntity> {
    return axios.post(
      `${process.env.REACT_APP_TRAVOLTA_URL}/auth/login`,
      userCredentials,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    ).then(({ data }) => data);
  }
}
