import { UseCase } from '../../base/use-case';
import { WarehouseModel } from '../../domain/warehouse/warehouse.model';
import WarehouseRepository from '../../repositories/warehouse.repository';

export default class GetWarehousesUseCase implements UseCase<void, WarehouseModel[]> {
  private warehouseRepository : WarehouseRepository;

  constructor(warehouseRepository : WarehouseRepository) {
    this.warehouseRepository = warehouseRepository;
  }

  execute(): Promise<WarehouseModel[]> {
    return this.warehouseRepository.getWarehouses();
  }
}
