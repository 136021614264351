import WarehouseApiService from './warehouse-api.service';
import { WarehouseModel } from '../../../core/domain/warehouse/warehouse.model';
import WarehouseRepository from '../../../core/repositories/warehouse.repository';
import WarehouseMapper from './mappers/warehouse.mapper';

export default class WarehouseRepositoryImpl implements WarehouseRepository {
  private warehouseApiService : WarehouseApiService;

  private warehouseMapper : WarehouseMapper;

  constructor(warehouseApiService : WarehouseApiService, warehouseMapper: WarehouseMapper) {
    this.warehouseApiService = warehouseApiService;
    this.warehouseMapper = warehouseMapper;
  }

  getWarehouses(): Promise<WarehouseModel[]> {
    return this.warehouseApiService.fetchWarehouses()
      .then((warehouseEntities) => warehouseEntities.map(
        (warehouseEntity) => this.warehouseMapper.mapFrom(warehouseEntity),
      ));
  }
}
