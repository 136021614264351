import LoginUserUseCase from './core/usecases/user/login-user.usecase';
import UserRepositoryImpl from './data/repositories/user/user-impl.repository';
import UserApiService from './data/repositories/user/user-api.service';
import UserCredentialsMapper from './data/repositories/user/mappers/user-credentials.mapper';
import GetWarehousesUseCase from './core/usecases/warehouse/get-warehouses.usecase';
import WarehouseRepositoryImpl from './data/repositories/warehouse/warehouse-impl.repository';
import WarehouseApiService from './data/repositories/warehouse/warehouse-api.service';
import UserMapper from './data/repositories/user/mappers/user.mapper';
import WarehouseMapper from './data/repositories/warehouse/mappers/warehouse.mapper';
import ConvertPicklistUseCase from './core/usecases/picklist/convert-picklist-use.case';
import PicklistItemMapper from './data/repositories/picklist/mappers/picklist-item.mapper';
import PicklistMapper from './data/repositories/picklist/mappers/picklist.mapper';
import ImportPicklistUseCase from './core/usecases/picklist/import-picklist-use.case';
import PicklistExcelService from './data/repositories/picklist/picklist-excel.service';
import PicklistApiService from './data/repositories/picklist/picklist-api.service';
import PicklistRepositoryImpl from './data/repositories/picklist/picklist-impl.repository';

const userMapper = new UserMapper();
const userCredentialsMapper = new UserCredentialsMapper();
const userApiService = new UserApiService();
const userRepositoryImpl = new UserRepositoryImpl(
  userApiService,
  userMapper,
  userCredentialsMapper,
);
const loginUserUseCase = new LoginUserUseCase(userRepositoryImpl);

const warehouseMapper = new WarehouseMapper();
const warehouseApiService = new WarehouseApiService();
const warehouseRepositoryImpl = new WarehouseRepositoryImpl(warehouseApiService, warehouseMapper);
const getWarehousesUseCase = new GetWarehousesUseCase(warehouseRepositoryImpl);

const picklistExcelService = new PicklistExcelService();
const picklistItemMapper = new PicklistItemMapper();
const picklistMapper = new PicklistMapper(picklistItemMapper);
const picklistApiService = new PicklistApiService();
const picklistRepositoryImpl = new PicklistRepositoryImpl(
  picklistApiService,
  picklistExcelService,
  picklistMapper,
);
const importPickListUseCase = new ImportPicklistUseCase(picklistRepositoryImpl);
const convertPicklistUseCase = new ConvertPicklistUseCase(picklistRepositoryImpl);

export {
  loginUserUseCase, getWarehousesUseCase, convertPicklistUseCase, importPickListUseCase,
};
