import { ToMapper } from '../../../../core/base/mapper';
import { PicklistItemModel } from '../../../../core/domain/picklist/picklist.model';
import { PicklistItemEntity } from '../entities/picklist.entity';

export default class PicklistItemMapper implements
    ToMapper<PicklistItemEntity, PicklistItemModel> {
  // eslint-disable-next-line class-methods-use-this
  mapTo({ variantId, quantity }: PicklistItemModel): PicklistItemEntity {
    return { variantId, quantity };
  }
}
