import React from 'react';
import {
  BrowserRouter, Navigate, Route, Routes,
} from 'react-router-dom';
import ImportPicklist from './picklists/components/ImportPicklist';
import AssignPicklist from './picklists/components/AssignPicklist';
import './App.css';
import Login from './auth/components/Login';
import {
  loginUserUseCase,
  getWarehousesUseCase,
  convertPicklistUseCase,
  importPickListUseCase,
} from '../dependency-injection.config';
import { AuthProvider, RequireAuth } from './shared/auth/Auth';
import Header from './shared/Header';

function App() {
  return (
    <div className="App">
      <AuthProvider loginUseCase={loginUserUseCase}>
        <BrowserRouter>
          <Routes>
            <Route path="/picklists" element={<Header />}>
              <Route element={<RequireAuth />}>
                <Route path="import" element={<ImportPicklist convertPicklistUseCase={convertPicklistUseCase} importPicklistUseCase={importPickListUseCase} />} />
                <Route path="assign/:picklistId" element={<AssignPicklist />} />
              </Route>
            </Route>
            <Route path="/login" element={<Login getWarehousesUseCase={getWarehousesUseCase} />} />
            <Route path="*" element={<Navigate to="/picklists/import" />} />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </div>
  );
}

export default App;
