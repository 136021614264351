import { UseCase } from '../../base/use-case';
import { UserCredentialsModel } from '../../domain/user/user-credentials.model';
import { UserModel } from '../../domain/user/user.model';
import UserRepository from '../../repositories/user.repository';

export default class LoginUserUseCase implements UseCase<UserCredentialsModel, UserModel> {
  private userRepository : UserRepository;

  constructor(userRepository : UserRepository) {
    this.userRepository = userRepository;
  }

  execute(params: UserCredentialsModel): Promise<UserModel> {
    return this.userRepository.login(params);
  }
}
