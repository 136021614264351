import axios, { AxiosError } from 'axios';
import { PicklistEntity } from './entities/picklist.entity';
import PicklistWithSameNameExistsError from '../../../core/domain/picklist/errors/picklist-with-same-name-exists.error';
import GeneralError from '../../../core/domain/picklist/errors/general.error';
import QuantityLessOrEqualZeroError from '../../../core/domain/picklist/errors/quantity-less-or-equal-zero.error';
import MissingOrEmptyMandatoryFieldError
  from '../../../core/domain/picklist/errors/missing-or-empty-mandatory-field.error';
import ForbiddenError from '../../../presentation/shared/errors/forbidden.error';

export default class PicklistApiService {
  // eslint-disable-next-line class-methods-use-this
  public importPicklist(picklist: PicklistEntity): Promise<string> {
    return axios.post(
      `${process.env.REACT_APP_TRAVOLTA_URL}/pick-lists`,
      picklist,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${JSON.parse(localStorage.getItem('wmsUser') || '{}').accessToken}`,
        },
      },
    ).then(({ data }) => data.id).catch((e) => {
      if (axios.isAxiosError(e)) {
        throw PicklistApiService.convertAxiosError(e);
      } else {
        throw e;
      }
    });
  }

  private static convertAxiosError(e: AxiosError) : Error {
    if (e.response?.status === 409) {
      const code = e.response?.data.code;
      if (code === 'picklist_already_exists_with_same_name') {
        return new PicklistWithSameNameExistsError();
      }
    } else if (e.response?.status === 400) {
      const code = e.response?.data.code;
      if (code === 'quantity_is_less_or_equal_zero') {
        return new QuantityLessOrEqualZeroError();
      } if (code === 'missing_or_empty_mandatory_field') {
        return new MissingOrEmptyMandatoryFieldError(e.response?.data.error);
      }
    } else if (e.response?.status === 401) {
      return new ForbiddenError();
    }

    return new GeneralError();
  }
}
