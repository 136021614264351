import React, { FC } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { AuthContextType, useAuth } from './auth/Auth';

interface HeaderProps {}

const Header: FC<HeaderProps> = function Header() : JSX.Element {
  const auth: AuthContextType = useAuth();
  const navigate = useNavigate();

  function handleLogout() {
    return auth.logout().then(() => {
      navigate('/');
    });
  }

  return (
    <div>
      <nav className="navbar navbar-light bg-light">
        <div className="navbar-brand">WMS portal</div>
        <button className="btn btn-outline-success ms-auto" onClick={handleLogout} type="submit">Logout</button>
      </nav>
      <Outlet />
    </div>
  );
};

export default Header;
