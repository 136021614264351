import React, { FC, useState } from 'react';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { UseCase } from '../../../core/base/use-case';
import { PicklistItemModel, PicklistModel } from '../../../core/domain/picklist/picklist.model';
import FileFormatMismatchError from '../../../core/domain/picklist/errors/file-format.error';
import Spinner from '../../shared/Spinner';
import GeneralError from '../../../core/domain/picklist/errors/general.error';
import PicklistWithSameNameExistsError from '../../../core/domain/picklist/errors/picklist-with-same-name-exists.error';
import QuantityLessOrEqualZeroError from '../../../core/domain/picklist/errors/quantity-less-or-equal-zero.error';
import MissingOrEmptyMandatoryFieldError
  from '../../../core/domain/picklist/errors/missing-or-empty-mandatory-field.error';
import ForbiddenError from '../../shared/errors/forbidden.error';
import { useAuth } from '../../shared/auth/Auth';

interface ImportPicklistProps {
  convertPicklistUseCase : UseCase<File, PicklistItemModel[]>
  importPicklistUseCase : UseCase<PicklistModel, string>
}

const ImportPicklist: FC<ImportPicklistProps> = function ImportPickList(
  { convertPicklistUseCase, importPicklistUseCase },
) : JSX.Element {
  const [picklistName, setPicklistName] = useState<string>('');
  const [picklistFile, setPicklistFile] = useState<File | null>();
  const [importInProgress, setImportInProgress] = useState<boolean>();
  const [hasErrorWhileImporting, setHasErrorWhileImporting] = useState<boolean>();
  const [importingErrorMessage, setImportingErrorMessage] = useState<string>();

  const navigate = useNavigate();
  const auth = useAuth();

  function areInputsValid(): boolean {
    return !_.isEmpty(picklistName) && picklistFile !== null;
  }

  async function handleImportError(e: Error) {
    if (e instanceof ForbiddenError) {
      await auth.logout();
      return;
    }

    setHasErrorWhileImporting(true);
    if (e instanceof FileFormatMismatchError) {
      setImportingErrorMessage(`Error while parsing file: ${e.message}`);
    } else if (e instanceof GeneralError) {
      setImportingErrorMessage('General error while calling api');
    } else if (e instanceof PicklistWithSameNameExistsError) {
      setImportingErrorMessage('Picklist with same name already exists');
    } else if (e instanceof QuantityLessOrEqualZeroError) {
      setImportingErrorMessage('Quantity of product is less or equal zero');
    } else if (e instanceof MissingOrEmptyMandatoryFieldError) {
      setImportingErrorMessage(`Missing or empty mandatory field: ${e.message}`);
    } else {
      setImportingErrorMessage(`General error while importing file: ${e.message}`);
    }
  }

  function importConverted(picklistItems : PicklistItemModel[]) : Promise<string> {
    return importPicklistUseCase.execute({
      name: picklistName,
      items: picklistItems,
    });
  }

  function navigateToImportedPicklist(picklistId : string) {
    navigate(`/picklists/assign/${picklistId}`);
  }

  function submitExcelSheet() {
    if (picklistFile) {
      setImportInProgress(true);
      convertPicklistUseCase.execute(picklistFile)
        .then(importConverted)
        .then(navigateToImportedPicklist)
        .finally(() => setImportInProgress(false))
        .catch(handleImportError);
    }
  }

  function importingErrorComponent(): JSX.Element {
    return (
      <div className="mb-3">
        <div className="alert alert-danger" role="alert">
          <h4 className="alert-heading">Error summary</h4>
          {importingErrorMessage}
        </div>
      </div>
    );
  }

  return (
    <div className="d-flex justify-content-center container">
      {importInProgress ? <Spinner /> : null}
      <div className={importInProgress ? '' : '.d-none'}>
        {hasErrorWhileImporting ? importingErrorComponent() : null}
        <div className="mb-3">
          <label className="form-label" htmlFor="usernameInput">Picklist name</label>
          <input
            type="text"
            className="form-control"
            id="usernameInput"
            onChange={(e) => setPicklistName(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="formFile" className="form-label">Picklist file</label>
          <input className="form-control" type="file" id="formFile" accept=".xlsx" onChange={(e) => setPicklistFile(e.target.files![0])} />
        </div>
        <div>
          <button type="submit" className="btn secondary"><a href={`${process.env.PUBLIC_URL}/picklist-template.xlsx`} download="picklist-template.xlsx">Download picklist file example</a></button>
          <button type="submit" className="btn btn-primary" disabled={!areInputsValid()} onClick={submitExcelSheet}>Submit</button>
        </div>
      </div>
    </div>
  );
};

export default ImportPicklist;
