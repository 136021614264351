import UserRepository from '../../../core/repositories/user.repository';
import { UserCredentialsModel } from '../../../core/domain/user/user-credentials.model';
import { UserModel } from '../../../core/domain/user/user.model';
import UserApiService from './user-api.service';
import UserMapper from './mappers/user.mapper';
import UserCredentialsMapper from './mappers/user-credentials.mapper';

export default class UserRepositoryImpl implements UserRepository {
  private userApiService : UserApiService;

  private userMapper : UserMapper;

  private userCredentialsMapper : UserCredentialsMapper;

  constructor(
    userApiService : UserApiService,
    userMapper: UserMapper,
    userCredentialsMapper: UserCredentialsMapper,
  ) {
    this.userApiService = userApiService;
    this.userCredentialsMapper = userCredentialsMapper;
    this.userMapper = userMapper;
  }

  login(userCredentials: UserCredentialsModel): Promise<UserModel> {
    return this.userApiService.login(this.userCredentialsMapper.mapTo(userCredentials))
      .then((userEntity) => this.userMapper.mapFrom(userEntity));
  }
}
