import { FromMapper } from '../../../../core/base/mapper';
import { WarehouseModel } from '../../../../core/domain/warehouse/warehouse.model';
import { WarehouseEntity } from '../entities/warehouse.entity';

export default class WarehouseMapper implements FromMapper<WarehouseEntity, WarehouseModel> {
  // eslint-disable-next-line class-methods-use-this
  mapFrom(param: WarehouseEntity): WarehouseModel {
    return { warehouseCode: param.warehouseCode, name: param.name };
  }
}
