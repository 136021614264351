import { ToMapper } from '../../../../core/base/mapper';
import { UserCredentialsEntity } from '../entities/user-credentials.entity';
import { UserCredentialsModel } from '../../../../core/domain/user/user-credentials.model';

export default class UserCredentialsMapper implements
    ToMapper<UserCredentialsEntity, UserCredentialsModel> {
  // eslint-disable-next-line class-methods-use-this
  mapTo({ password, username, warehouseCode }: UserCredentialsModel): UserCredentialsEntity {
    return { username, password, warehouseCode };
  }
}
