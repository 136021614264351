import { ToMapper } from '../../../../core/base/mapper';
import { PicklistModel } from '../../../../core/domain/picklist/picklist.model';
import { PicklistEntity, PicklistItemEntity } from '../entities/picklist.entity';

export default class PicklistMapper implements
    ToMapper<PicklistEntity, PicklistModel> {
  private picklistItemMapper : ToMapper<PicklistItemEntity, PicklistItemEntity>;

  constructor(picklistItemMapper : ToMapper<PicklistItemEntity, PicklistItemEntity>) {
    this.picklistItemMapper = picklistItemMapper;
  }

  mapTo({ name, items }: PicklistModel): PicklistEntity {
    return { name, items: items.map((i) => this.picklistItemMapper.mapTo(i)) };
  }
}
