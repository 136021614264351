import * as React from 'react';
import {
  useLocation,
  Navigate,
  Outlet,
} from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import _ from 'lodash';
import { jwtDecode } from 'jwt-decode';
import { UseCase } from '../../../core/base/use-case';
import { UserCredentialsModel } from '../../../core/domain/user/user-credentials.model';
import { UserModel, UserTokenDetailsModel } from '../../../core/domain/user/user.model';
import { AllowedRolesList } from '../../auth/allowedRoles';

export interface AuthContextType {
  user: UserModel;
  login: (userCredentials: UserCredentialsModel) => Promise<UserModel>;
  logout: () => Promise<void>;
}

const AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({ children, loginUseCase }:
{ children: React.ReactNode, loginUseCase: UseCase<UserCredentialsModel, UserModel> }) {
  const [user, setUser] = useLocalStorage<UserModel>('wmsUser', { accessToken: '' });

  const login = (userCredentials: UserCredentialsModel) : Promise<UserModel> => loginUseCase
    .execute(userCredentials)
    .then((loggedInUser) => { setUser(loggedInUser); return loggedInUser; });

  const logout = () : Promise<void> => {
    setUser({ accessToken: '' });
    return Promise.resolve();
  };

  // To be honest, I don't know how to fix this (yet)
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = { user, login, logout };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return React.useContext(AuthContext);
}

export function RequireAuth() {
  const auth = useAuth();
  const location = useLocation();

  if (_.isEmpty(auth.user.accessToken)) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (auth.user?.accessToken) {
    const { accessToken } = auth.user;
    const decodedToken: UserTokenDetailsModel = jwtDecode(accessToken);
    const userRole = decodedToken.user.userRoles[0].role;
    if (!AllowedRolesList.includes(userRole)) {
      return <Navigate to="/login" state={{ from: location }} />;
    }
  }
  return <Outlet />;
}
