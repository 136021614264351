import React, { FC } from 'react';

interface SpinnerProps {}

const Spinner: FC<SpinnerProps> = function Spinner() : JSX.Element {
  return (

    <div className="mb-3">
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  );
};

export default Spinner;
