import { read, utils } from 'xlsx';
import { PicklistItemModel } from '../../../core/domain/picklist/picklist.model';
import FileFormatMismatchError from '../../../core/domain/picklist/errors/file-format.error';

export default class PicklistExcelService {
  // eslint-disable-next-line class-methods-use-this
  public convert(picklistFile: File): Promise<PicklistItemModel[]> {
    return PicklistExcelService.promisifyFetch(picklistFile)
      .then((buffer) => PicklistExcelService.convertBufferToPicklistItems(buffer));
  }

  private static convertBufferToPicklistItems(ab: ArrayBuffer) {
    const workbook = read(ab, { type: 'array' });
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
    const json = utils.sheet_to_json(worksheet);

    const picklistItems: PicklistItemModel[] = [];
    for (let i = 0; i < json.length; i += 1) {
      picklistItems.push(PicklistExcelService.convertItems(json, i));
    }

    return picklistItems;
  }

  private static convertItems(json: unknown[], i: number) {
    const jsonElement = json[i];

    // @ts-ignore
    if (!jsonElement.SKU) {
      throw new FileFormatMismatchError(`Error at row ${i}: SKU field could not be found`);
    }

    // @ts-ignore
    if (!jsonElement.QUANTITY) {
      throw new FileFormatMismatchError(`Error at row ${i}: Quantity field could not be found`);
    }
    // @ts-ignore
    if (!Number.isInteger(jsonElement.QUANTITY)) {
      throw new FileFormatMismatchError(`Error at row ${i}: Quantity field is not a number`);
    }

    // @ts-ignore
    return { variantId: jsonElement.SKU, quantity: jsonElement.QUANTITY };
  }

  private static promisifyFetch(file : File) : Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const fr = new FileReader();
      fr.onload = (e) => {
        if (e.target!.result instanceof ArrayBuffer) {
          resolve(e.target!.result);
        } else {
          throw new FileFormatMismatchError('Uncategorized exception, please get in touch with customer support');
        }
      };
      fr.onerror = reject;
      fr.readAsArrayBuffer(file);
    });
  }
}
